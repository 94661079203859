import React from 'react';

import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from 'common/theme/appModern';
import { ResetCSS } from 'common/assets/css/style';
import Sticky from 'react-stickynode';

import Navbar from 'containers/AppModern/Navbar';
import PicturesGallery from 'containers/AppMinimal/PicturesGallery';
import Footer from 'containers/AppMinimal/Footer';

import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from 'containers/AppModern/appModern.style';
import '@redq/reuse-modal/es/index.css';

import Seo from 'components/seo';

const Gallerie = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Seo title="Cryogenite" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          
          <ContentWrapper>
            <PicturesGallery />
          </ContentWrapper>
          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
};
export default Gallerie;
