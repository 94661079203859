import React from 'react';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import Heading from 'common/components/Heading';

import ContentWrapper from './picturesGallery.style';
import '@redq/reuse-modal/es/index.css';

import Before1 from '../../../common/assets/image/beforeAfter/avant 1.jpg';
import After1 from '../../../common/assets/image/beforeAfter/après 1.jpg';
import Before2 from '../../../common/assets/image/beforeAfter/avant 2.jpg';
import After2 from '../../../common/assets/image/beforeAfter/après 2.jpg';
import Before3 from '../../../common/assets/image/beforeAfter/avant 3.jpg';
import After3 from '../../../common/assets/image/beforeAfter/après 3.jpg';
import Before4 from '../../../common/assets/image/beforeAfter/avant 4.jpg';
import After4 from '../../../common/assets/image/beforeAfter/après 4.jpg';
import Before5 from '../../../common/assets/image/beforeAfter/avant5.jpg';
import After5 from '../../../common/assets/image/beforeAfter/après5.jpg';
import Before6 from '../../../common/assets/image/beforeAfter/avant6.jpg';
import After6 from '../../../common/assets/image/beforeAfter/après6.jpg';
import Before7 from '../../../common/assets/image/beforeAfter/avant7.jpg';
import After7 from '../../../common/assets/image/beforeAfter/après7.jpg';
import Before8 from '../../../common/assets/image/beforeAfter/avant8.jpg';
import After8 from '../../../common/assets/image/beforeAfter/après8.jpg';


const PicturesGallery = () => {

  const arrayPictures = [
      {
        FIRST_IMAGE: {imageUrl: Before1},
        SECOND_IMAGE: {imageUrl: After1}
      },
      {
        FIRST_IMAGE: {imageUrl: Before2},
        SECOND_IMAGE: {imageUrl: After2}
      },
      {
        FIRST_IMAGE: {imageUrl: Before3},
        SECOND_IMAGE: {imageUrl: After3}
      },
      {
        FIRST_IMAGE: {imageUrl: Before4},
        SECOND_IMAGE: {imageUrl: After4}
      },
  ]

  const arrayPictures2 = [
    {
      FIRST_IMAGE: {imageUrl: Before5},
      SECOND_IMAGE: {imageUrl: After5}
    },
    {
      FIRST_IMAGE: {imageUrl: Before6},
      SECOND_IMAGE: {imageUrl: After6}
    },
    {
      FIRST_IMAGE: {imageUrl: Before7},
      SECOND_IMAGE: {imageUrl: After7}
    },
    {
      FIRST_IMAGE: {imageUrl: Before8},
      SECOND_IMAGE: {imageUrl: After8}
    }
  ]

  const delimiterIconStyles = {
    width: '20px',
    height: '20px',
  }

  const delimiterIconStyles2 = {
    width: '20px',
    height: '20px',
    marginBottom: '11em'
  }

  return (          
    <ContentWrapper className="container">
      <Heading className="header" content="Compartiment moteur – BMW E36" />
        <div className="container-before-after">
          {arrayPictures.map(pictureLinks => {
            return (
                <ReactBeforeSliderComponent
                    firstImage={pictureLinks.FIRST_IMAGE}
                    secondImage={pictureLinks.SECOND_IMAGE}
                    className="picture-before-after"
                    delimiterIconStyles={delimiterIconStyles}

                />
            )})}
        </div>
      <Heading className="second-header" content="Bloc avant – VW Golf 8 GTD" />
        <div className="container-before-after">
          {arrayPictures2.map(pictureLinks => {
            return (
                <ReactBeforeSliderComponent
                    firstImage={pictureLinks.FIRST_IMAGE}
                    secondImage={pictureLinks.SECOND_IMAGE}
                    className="picture-before-after"
                    delimiterIconStyles={delimiterIconStyles2}
                />
            )})}
        </div>
    </ContentWrapper>
  );
};
export default PicturesGallery;
