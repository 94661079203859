import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { rgba } from 'polished';

const ContentWrapper = styled.section`
  .header {
    margin-top: 6em;
    text-align: center;
    font-size: 2em;
    @media (max-width: 480px) {
      margin-top: 3em;
    }
  }
  .second-header {
    margin-top: -2em;
    text-align: center;
    font-size: 2em;
  }
  .container-before-after {
    display: flex;
    @media (max-width: 480px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .picture-before-after {
    margin: 2em;
    height: 720px;
    width: 480px;
    @media (max-width: 480px) {
      height: 420px;
      width: 280px;
    }
  }
  .before-after-slider__first-photo-container {
    filter: drop-shadow(6px 6px 4px grey);
  }
  .before-after-slider__delimiter {
    height: 71.5%;
    @media (max-width: 480px) {
      height: 83%;

    }
  }
`;

export const TextWrapper = styled.div`
    h3 {
      color: ${themeGet('colors.headingColor', '#09131F')};
      font-size: 18px;
      line-height: 1.45;
      font-weight: 700;
      margin-bottom: 12px;
      @media (max-width: 1600px) {
        font-size: 16px;
      }
    }
    p {
      color: ${themeGet('colors.textColor', '#343D48')};
      font-size: 18px;
      line-height: 1.92;
      @media (max-width: 1600px) {
        font-size: 14px;
      }
    }
  }
`;

export default ContentWrapper;
